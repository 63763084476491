import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import db from "../../firebase";
import { getAuthFromLocal } from "../../utils/storage";
import dayjs from "dayjs"; 

const MessageTable = ({ isRefresh, setRefresh }) => {
  const user = getAuthFromLocal();
  // Columns configuration
  const columns = [
    {
      field: "createdAt",
      headerName: "Time",
      width: 140,
      valueGetter: (params) =>
        params && params !== "N/A"
          ? new Date(params).toLocaleString(undefined, {
              dateStyle: "short", // Displays the date (e.g., MM/DD/YYYY)
              timeStyle: "short", // Displays the time (e.g., 10:45 PM)
            })
          : "N/A",
    },
    {
      field: "ticketNumber",
      headerName: "Ticket Number",
      width: 140,
      valueGetter: (params) => params || "Not Assigned",
    },
    { field: "buyer", headerName: "Buyer", width: 200 },
    {
      field: "message",
      headerName: "Message",
      width: 300,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal", // Allow text to wrap
            wordBreak: "break-word", // Break long words if necessary
            fontSize: "0.875rem", // Smaller font size for readability
            lineHeight: "1.2", // Tighten the line spacing
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "url",
      headerName: "Attachment",
      width: 140,
      renderCell: (params) =>
        params?.value ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const url = params.value;
              window.open(url, "_blank");
            }}
          >
            Download
          </Button>
        ) : (
          <span>No Attachment</span>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        const status = params?.row?.status || "unknown";
        const color =
          status === "open"
            ? "#FFD700"
            : status === "start"
            ? "#87CEFA"
            : status === "submitted"
            ? "#E71D36"
            : status === "done"
            ? "#32CD32"
            : "#D3D3D3";

        return (
          <span
            style={{
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: color,
              color: "white",
              fontWeight: "bold",
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "ticketAge",
      headerName: "Ticket Age",
      width: 140,
      renderCell: (params) => {
        const ticketTimestamp = params?.row.completeDate ?? params.row.timeStamp; // Assuming `timestamp` is in ISO format
        
        if (!ticketTimestamp) {
          // Handle missing timestamp
          return <span style={{ color: "gray" }}>N/A</span>;
        }
    
        const now = dayjs();
        const ticketTime = dayjs(ticketTimestamp);
    
        const hoursAgo = now.diff(ticketTime, "hour");
        const daysAgo = now.diff(ticketTime, "day");
    
        // Calculate how to display age
        let displayText = "";
        if (hoursAgo < 1) {
          displayText = `${now.diff(ticketTime, "minute")} minutes ago`;
        } else if (hoursAgo < 24) {
          displayText = `${hoursAgo} hours ago`;
        } else {
          displayText = `${daysAgo} days ago`;
        }
    
        // Determine background color for old tickets
        const isOlderThan24Hours = hoursAgo >= 24;
        const cellStyle = {
          backgroundColor: isOlderThan24Hours ? "red" : "transparent",
          color: isOlderThan24Hours ? "white" : "inherit",
          padding: "8px",
          borderRadius: "4px",
          fontWeight: isOlderThan24Hours ? "bold" : "normal",
          textAlign: "center", // Fixed typing issue
        };
    
        return <span style={cellStyle}>{displayText}</span>;
      },
    }
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      setRefresh()
    }
  }, [isRefresh]);


  const fetchData = async () => {
    try {
      const q = query(
        collection(db, "messages"),
        where("buyer", "==", user.username),
      );
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc, i) => ({
        id: i + 1,
        buyer: doc.data().buyer || "Unknown Buyer",
        url: doc.data().url || null,
        status: doc.data().status || "unknown",
        completeDate: doc.data()?.completeDate || null,
        createdAt: doc.data().timestamp || "N/A", // Renamed timestamp
        timeStamp: doc.data().timestamp || null, // Renamed timestamp
        ticketNumber: doc.data().ticketNumber || "Not Assigned",
        message: doc.data().message,
      }));
      setData(fetchedData);
    } catch (e) {
      console.error("Error fetching data: ", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{ height: 400, width: "100%", py: 4, backgroundColor: 'white' }}
    >
      <DataGrid
        pageSizeOptions={[20, 30, 40, { value: -1, label: "All" }]}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        style={{
          overflow: "hidden", // Prevent unnecessary scrollbars
        }}
        disableVirtualization
        loading={loading}
        rows={data || []}
        columns={columns}
        getRowId={(row) => row.id}
        sx={{
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
            backgroundColor: "#566270",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important",

            // Adjust as needed
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
          },
        }}
      />
    </Box>
  );
};

export default MessageTable;
