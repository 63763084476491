import {initializeApp} from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {getStorage } from 'firebase/storage';

import { getDownloadURL, uploadBytes, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};  
const firebase = initializeApp(firebaseConfig);
const storage = getStorage(firebase);
const db = getFirestore(firebase)

export async function uploadImage(image: any) {
  const storageRef = ref(storage, `/orders.xlsx`);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
}

export async function uploadDocument(image: any) {
  const storageRef = ref(storage, `/sellOrder.xlsx`);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
}

export async function uploadDocumentForMaster(image: any, name: string) {
  const storageRef = ref(storage, `/${name}.xlsx`);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
}

export async function uploadDocumentWithName(image: any, name: string) {
  const storageRef = ref(storage, `/attachment/${name}`);
  const response = await uploadBytes(storageRef, image);
  const url = await getDownloadURL(response.ref);
  return url;
}

export default db;