import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem as SelectMenuItem,
  InputLabel,
  FormControl,
  Grid2,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import db from "../../firebase";
import { toast } from "react-toastify";
import { UserData, UserMessage } from "../../type/user";
import {
  buyersColumn,
  extractExcelData,
  processExcelDataForBuyers,
  processExcelDataForMessage,
  processExcelDataForUser,
  roles,
  sheetNames,
  usersColumn,
} from "../../utils";
import UserTable from "../../component/atom/UserTable";
import BuyerTable from "../../component/atom/BuyerTable";
import { useDispatch, useSelector } from "react-redux";
import { setBuyerRefresh, setRefresh } from "../../store/userSlice";
import { getAuthFromLocal } from "../../utils/storage";

const UserPage = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isUploading, setIsuploading] = useState(false);
  const user = getAuthFromLocal();
  const [isSpecialUploading, setIsSpecialUploading] = useState(false);
  const [isBuyerUploading, setIsBuyerUploading] = useState(false);
  const { users, message } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      username: "",
      phoneNumber: "",
      password: "",
      role: "",
      buyers: "", // Start with an empty string for buyers
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      phoneNumber: Yup.string()
        .length(10, "Phone number should be exactly 10 digits")
        .required("Phone number is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      role: Yup.string().required("Role is required"),
      buyers: Yup.string().test(
        "buyers-validation",
        "Buyer group is required when role is KAM",
        function (value) {
          const { role } = this.parent; // Access the parent form values (role)
          if (role === "KAM" && (!value || value.trim() === "")) {
            return false; // If role is 'KAM' and buyers is empty, it's invalid
          }
          return true; // Otherwise, it's valid
        }
      ),
    }),
    onSubmit: (values) => {
      const user: UserData = {
        username: values.username.trim(),
        phoneNumber: values.phoneNumber.toString().trim(),
        password: values.password.trim(),
        role: values.role.trim(),
        buyers: values?.buyers ? values.buyers?.split(",") : [],
        status: "ACTIVE",
      };

      upsertUserOrBuyer(user.phoneNumber.toString().trim(), user);
      toast.success("User added");
      setCreateDialogOpen(false); // Close the create dialog after submission
      dispatch(setRefresh(true));
    },
  });

  const handleCreateUser = () => {
    formik.resetForm();
    setCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  const handleBuyersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("buyers", event.target.value);
  };

  useEffect(() => {
    if (formik.values.role !== "KAM") {
      formik.setFieldValue("buyers", "");
    }
  }, [formik.values.role]);

  const upsertUserOrBuyer = async (phoneNumber: string, userData: UserData) => {
    const usersCollection = collection(db, "users");

    // Query to check if the user exists
    const q = query(usersCollection, where("phoneNumber", "==", phoneNumber));

    try {
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // User exists, update the 'buyers' array field
        for (const docSnapshot of querySnapshot.docs) {
          const userRef = doc(db, "users", docSnapshot.id);

          const buyers = userData?.buyers ?? [];

          await updateDoc(userRef, {
            role: userData.role,
            password: userData?.password?.trim() ?? null,
            buyers:
              Array.isArray(buyers) && buyers.length > 0
                ? arrayUnion(...buyers)
                : [],
            status: userData?.status?.trim() ?? null,
          });
          console.log(
            `Buyer name "${userData.buyers?.join(
              ","
            )}" added to user with phone number ${phoneNumber}.`
          );
        }
      } else {
        // User doesn't exist, insert a new user with the buyers array field initialized
        const newUserRef = doc(usersCollection);
        await setDoc(newUserRef, {
          ...userData,
          buyers: userData?.buyers ?? [],
          status: userData?.status?.trim() ?? null,
          phoneNumber: userData.phoneNumber.toString().trim(),
          password: userData?.password?.toString().trim() ?? null,
          username: userData.username?.toString().trim() ?? null,
        });
        console.log(`New user with phone number ${phoneNumber} inserted.`);
      }
    } catch (error) {
      console.error(
        `Error processing user with phone number ${phoneNumber}: `,
        error
      );
    }
  };

  const addSpecialMessage = async (
    phoneNumber: string,
    userData: UserMessage
  ) => {
    const usersCollection = collection(db, "users");
    // Query to check if the user exists
    const q = query(usersCollection, where("phoneNumber", "==", phoneNumber));
    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // User exists, update the 'buyers' array field by adding a new buyer name
        querySnapshot.forEach(async (docSnapshot) => {
          const userRef = doc(db, "users", docSnapshot.id);

          // Use arrayUnion to add the buyer name string to the array, ensuring no duplicates
          await updateDoc(userRef, {
            outstanding: userData.outstanding,
            dueDate: userData.dueDate,
            message: userData.message,
          });
          console.log(
            `special message added to user with phone number ${phoneNumber}.`
          );
        });
      }
    } catch (error) {
      console.error(
        `Error processing user with phone number ${phoneNumber}: `,
        error
      );
    }
  };

  const addBrandsToBuyer = async (documentId: string, brandArray: string[]) => {
    try {
      const docRef = doc(db, "buyerGroup", documentId); // Reference to the document
      const docSnapshot = await getDoc(docRef); // Fetch the document snapshot

      if (docSnapshot.exists()) {
        // If document exists, update the `brand` array without duplicates
        const existingData = docSnapshot.data();
        const existingBrands = existingData.brandName || [];

        // Merge arrays and remove duplicates
        const updatedBrands = Array.from(
          new Set([...existingBrands, ...brandArray])
        );

        // Update the document
        await updateDoc(docRef, { brandName: updatedBrands });
        console.log(`Document with ID '${documentId}' updated successfully.`);
      } else {
        // If document does not exist, create it
        await setDoc(docRef, { brandName: brandArray });
        console.log(`Document with ID '${documentId}' created successfully.`);
      }
    } catch (error) {
      console.error("Error handling Firestore document:", error);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsuploading(true);
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const excelData = extractExcelData(
          event.target?.result as ArrayBuffer,
          sheetNames.USER_BULK
        );

        // Process the Excel data
        const users = processExcelDataForUser(excelData);
        for (const user of users) {
          await upsertUserOrBuyer(user.phoneNumber.toString(), user);
        }

        setIsuploading(false);
        toast.success("Users added");
        dispatch(setRefresh(true));
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleSpecialMessage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSpecialUploading(true);
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        // Convert the sheet to a JSON array
        const excelData = extractExcelData(
          event.target?.result as ArrayBuffer,
          sheetNames.SPECIAL_MESSAGE
        );

        // Process the Excel data
        const users = processExcelDataForMessage(excelData);

        for (const user of users) {
          await addSpecialMessage(user.phoneNumber, user);
        }

        setIsSpecialUploading(false);
        toast.success("Message added");
        dispatch(setBuyerRefresh(true));
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleBuyerUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsBuyerUploading(true);
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        // Convert the sheet to a JSON array
        const excelData = extractExcelData(
          event.target?.result as ArrayBuffer,
          sheetNames.BUYERGROUP_BRAND
        );

        // Process the Excel data
        const users = processExcelDataForBuyers(excelData);

        for (const user of users) {
          await addBrandsToBuyer(user.buyerGroup, user?.brand);
        }

        setIsBuyerUploading(false);
        toast.success("Buyer group added with its brands");
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleExport = async (
    tableData: any[],
    columns: any[],
    sheetname: string
  ) => {
    const dataWithoutStaticColumns =
      sheetname === sheetNames.USER_BULK
        ? tableData
            .filter((i) => i.role !== "SUPER_ADMIN")
            .map(({ id, message, buyers, outstanding, dueDate, ...rest }) => ({
              ...rest,
              buyers: buyers ? buyers.join(",") : "",
            }))
        : tableData.map(
            ({ id, buyers, role, password, status, ...rest }) => rest
          );

    const worksheet = XLSX.utils.json_to_sheet(dataWithoutStaticColumns, {
      header: columns.map((col) => col.field),
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetname);

    // Convert workbook to an array buffer (binary data)
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Create a Blob from the binary data and use FileSaver to trigger a download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${sheetname}.xlsx`); // This will trigger the download
  };

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 2, bgcolor: "#f9f7f3" }}
      className="container mx-auto"
    >
      <Typography sx={{ marginBottom: "20px" }} variant="h5">
        Users
      </Typography>
      <Grid2 container spacing={2} columns={{ sm: 8, lg: 12, xs: 4, md: 8 }}>
        <Grid2 size={{ lg: 4, md: 6, xs: 12 }}>
          <Button
            disabled={user?.role === "KAM"}
            variant="contained"
            onClick={handleCreateUser}
            className="w-full"
          >
            Create User
          </Button>
        </Grid2>
        <Grid2 size={{ lg: 4, md: 6, xs: 12 }}>
          <Button
            disabled={isUploading || user?.role === "KAM"}
            variant="contained"
            component="label"
            className="w-full"
          >
            {isUploading ? "Uploading..." : "Upload Users bulk"}
            <input
              type="file"
              hidden
              onChange={handleFileUpload}
              accept=".xlsx, .xls"
            />
          </Button>
        </Grid2>
        <Grid2 size={{ lg: 4, md: 6, xs: 12 }}>
          <Button
            className="w-full"
            disabled={isSpecialUploading}
            variant="contained"
            component="label"
          >
            {isSpecialUploading
              ? "Uploading..."
              : "Upload Special Message for buyers"}
            <input
              type="file"
              hidden
              onChange={handleSpecialMessage}
              accept=".xlsx, .xls"
            />
          </Button>
        </Grid2>
        <Grid2 size={{ lg: 4, md: 6, xs: 12 }}>
          <Button
            className="w-full"
            disabled={isBuyerUploading || user?.role === "KAM"}
            variant="contained"
            component="label"
          >
            {isBuyerUploading ? "Uploading..." : "Upload Buyers with brands"}
            <input
              type="file"
              hidden
              onChange={handleBuyerUpload}
              accept=".xlsx, .xls"
            />
          </Button>
        </Grid2>
      </Grid2>

      <div className="mt-[20px] flex gap-5">
        <span className="text-2xl">User Table</span>
        <Button
          onClick={() => handleExport(users, usersColumn, sheetNames.USER_BULK)}
          variant="contained"
          component="label"
        >
          Export Users
        </Button>
      </div>
      <UserTable />

      <div className="mt-[20px] flex gap-5">
        <span className="text-2xl">Buyer Table</span>
        <Button
          onClick={() =>
            handleExport(message, buyersColumn, sheetNames.SPECIAL_MESSAGE)
          }
          variant="contained"
          component="label"
        >
          Export Message
        </Button>
      </div>
      <BuyerTable />

      {/* Create User Dialog */}
      <Dialog open={createDialogOpen} onClose={handleCloseCreateDialog}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name="username"
              label="Username"
              fullWidth
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              sx={{ mb: 2, marginTop: "20px" }}
            />
            <TextField
              name="phoneNumber"
              label="Phone Number"
              type="number"
              fullWidth
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              sx={{ mb: 2 }}
            />
            <TextField
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Role</InputLabel>
              <Select
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              >
                {roles.map((role) => (
                  <SelectMenuItem key={role} value={role}>
                    {role}
                  </SelectMenuItem>
                ))}
              </Select>
              {formik.touched.role && formik.errors.role && (
                <Typography color="error">{formik.errors.role}</Typography>
              )}
            </FormControl>

            {formik.values.role === "KAM" && (
              <TextField
                name="buyers"
                label="Buyers (add comma separated)"
                fullWidth
                value={formik.values.buyers}
                onChange={handleBuyersChange}
                error={formik.touched.buyers && Boolean(formik.errors.buyers)}
                helperText={formik.touched.buyers && formik.errors.buyers}
                sx={{ mb: 2 }}
              />
            )}
            <DialogActions>
              <Button onClick={handleCloseCreateDialog}>Cancel</Button>
              <Button type="submit">Create</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UserPage;
