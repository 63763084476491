import React, { useState } from "react";
import {
  Modal,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from "@mui/material";

export const ProductSearchModal = ({
  open,
  onClose,
  products,
  onSelectProduct,
  onAddNewProduct,
}) => {
  const [searchText, setSearchText] = useState("");

  const handleClose = () => {
    setSearchText(""); // Clear search input when modal is closed
    onClose();
  };

  const handleSelectProduct = (product) => {
    onSelectProduct(product);
    setSearchText(""); // Clear search input after selection
    onClose();
  };

  const filteredProducts = products.filter((product) =>
    product.description.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxHeight: "80vh", // Set max height for the modal
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <TextField
          fullWidth
          label="Search Products"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ mb: 2 }}
        />
        <Box
          sx={{
            maxHeight: "60vh", // Limit the height of the product list
            overflowY: "auto", // Enable scrolling for the list
          }}
        >
          <List>
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <ListItem
                  button
                  key={product.id}
                  onClick={() => handleSelectProduct(product)}
                >
                  <ListItemText primary={product.description} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No products found" />
              </ListItem>
            )}
          </List>
          {filteredProducts.length === 0 && searchText.trim() !== "" && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                onAddNewProduct(searchText.trim());
                setSearchText(""); // Clear search input after adding
                onClose();
              }}
              sx={{ mt: 2 }}
            >
              Add as a New Product Description
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
