import { Box, CssBaseline, Drawer, IconButton, AppBar, Toolbar, useMediaQuery, useTheme, ThemeProvider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './molecule/Sidebar';
import { myTheme } from '../theme';

export const Layout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();

    const handleMenuItemClick = (item: any) => {
        navigate(item?.path)
        if(isDrawerOpen){
            setIsDrawerOpen(!isDrawerOpen);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth < 768) {
    //             setIsSidebarOpen(false);
    //         } else {
    //             setIsSidebarOpen(true);
    //         }
    //     };

    //     window.addEventListener('resize', handleResize);
    //     handleResize();
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const handleLogout = () => {
        localStorage.removeItem('currentUser');
        navigate('/login');
    };
    return (
        <div>
            <CssBaseline />
            <ThemeProvider theme={myTheme}>
            <Box sx={{ display: 'flex', height: '100vh' }}>
                {/* Sidebar for Desktop */}
                {/* {isSidebarOpen && (
                    <Sidebar
                        onMenuItemClick={handleMenuItemClick}
                        isSidebarOpen={isSidebarOpen}
                        toggleSidebar={toggleSidebar}
                    />
                )} */}

                {/* Drawer for Mobile/Tablet */}
                <Drawer
                    sx={{
                        width: 240,
                    }}
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={toggleDrawer}
                >
                    <Sidebar
                        onMenuItemClick={handleMenuItemClick}
                        isSidebarOpen={isDrawerOpen}
                        toggleSidebar={toggleDrawer}
                    />
                </Drawer>

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: '#f9f7f3',
                        transition: 'margin-left 0.3s ease',
                        overflowX: 'auto',
                    }}
                >
                    {/* Show AppBar only on mobile and tablet */}
                    {/* {isMobileOrTablet && (
                        
                    )} */}
                    <AppBar sx={{ position: 'sticky', top: 0, left: 0, right: 0, backgroundColor: "#2f353d" }}>
                            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className='flex items-center'>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    sx={{ mr: 2}}
                                    onClick={toggleDrawer}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <span className='font-black text-2xl'>MOLD-TEK</span>
                                </div>

                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    onClick={handleLogout}

                                >
                                    <LogoutIcon />

                                </IconButton>
                            </Toolbar>
                        </AppBar>

                    {/* Main content based on menu selection */}
                      <Outlet/>
                </Box>
            </Box>
            </ThemeProvider>
        </div>
    )
}

export default Layout