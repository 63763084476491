import React from "react";
import SellTable from "../../component/atom/SellTable";

const SellOrder = () => {
  return (
    <div className="p-5 container mx-auto">
      <SellTable />
    </div>
  );
};

export default React.memo(SellOrder);
