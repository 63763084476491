import { GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { BuyersData, UserData, UserMessage } from "../type/user";

export const roles = ["SUPER_ADMIN", "KAM", "USER"];

export const sheetNames = {
  ORDERS: 'ORDERS',
  USER_BULK: 'USER_BULK',
  BUYERGROUP_USER: 'BUYERGROUP_USER',
  BUYERGROUP_BRAND: 'BUYERGROUP_BRAND',
  SPECIAL_MESSAGE: 'SPECIAL_MESSAGE',
  SELL_ORDER: 'SELL_ORDER'
}

export const fetchAndParseExcel = async (url: string) => {
  try {
    // Fetch the Excel file from the download URL
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();

    // Read the file using XLSX library
    const data = new Uint8Array(arrayBuffer);
    const workbook = XLSX.read(data, { type: "array" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];

    // Convert the worksheet to JSON
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as string[][];
    
    // Return the parsed data
    return jsonData;

  } catch (error) {
    console.error("Error fetching or parsing Excel file:", error);
    return null;
  }
};

export const staticColumns: GridColDef[] = [
  { field: 'saleOrder', headerName: 'Sale Order', width: 150, editable: true},
  { field: 'buyerGroup', headerName: 'Buyer Group', width: 150, editable: true},
  { field: 'location', headerName: 'Location', width: 150, editable: true },
  { field: 'effectivityUnit', headerName: 'Effectivity Unit', width: 150, editable: true },
  { field: 'brand', headerName: 'Brand/Product', width: 350, editable: true },
  { field: 'category', headerName: 'Category', width: 150, editable: true },
  { field: 'size', headerName: 'Size', width: 100, editable: true },
  { field: 'orderDate', headerName: 'Order Date', width: 150, editable: true },
  { field: 'orderQuantity', headerName: 'Order Quantity', width: 150, editable: true },
  { field: 'dispatchedQuantity', headerName: 'Dispatched Quantity', width: 150, editable: true },
  { field: 'balanceQuantity', headerName: 'Balance Quantity', width: 150, editable: true },
  { field: 'dispatchDate', headerName: 'Dispatch Date', width: 150, editable: true },
  { field: 'status', headerName: 'Status', width: 120, editable: true },
  // { field: 'user', headerName: 'User', width: 150, editable: true },
  // { field: 'KAM', headerName: 'KAM', width: 150, editable: true },
  // { field: 'superadmin', headerName: 'Superadmin', width: 150, editable: true }
];

export const staticColumnsSellOrder: GridColDef[] = [
  { field: 'Site', headerName: 'Site', width: 150},
  { field: 'PO date', headerName: 'PO Date', width: 150 },
  { field: 'Call up Date', headerName: 'Call up Date', width: 150 },
  { field: 'Customer Order', headerName: 'Customer Order', width: 150 },
  { field: 'Order', headerName: 'Sales Order', width: 150 },
  { field: 'Customer', headerName: 'Customer Name', width: 150 },
  { field: 'Location', headerName: 'Location', width: 150 },
  { field: 'Buyer Group', headerName: 'Buyer Group', width: 150},
  { field: 'Size_AX', headerName: 'Size', width: 100},
  { field: 'Include Product Variant Features and Options on transactions', headerName: 'Name', width: 150 },
  { field: 'Ord. Qty', headerName: 'Sum of Order Quantity', width: 150 },
  { field: 'Delivered Quantity', headerName: 'Dispatch Quantity', width: 150 },
  { field: 'Bal. Qty', headerName: 'Sum of Balance Quantity', width: 150 },
  { field: 'KAM Cluster', headerName: 'KAM', width: 150 },
];

export const staticColumnsUser: GridColDef[] = [
  { field: 'location', headerName: 'Location', width: 150, editable: true },
  { field: 'brand', headerName: 'Brand/Product', width: 350 },
  { field: 'balanceQuantity', headerName: 'Balance Quantity', width: 180 },
  { field: 'status', headerName: 'Status', width: 180 },
  { field: 'orderQuantity', headerName: 'Order Quantity', width: 180 },
  { field: 'dispatchedQuantity', headerName: 'Dispatched Quantity', width: 200 },
  { field: 'orderDate', headerName: 'Order Date', width: 170,  },  

];

export const staticColumnsKAM: GridColDef[] = [
  { field: 'saleOrder', headerName: 'Sale Order', width: 150, editable: true},
  { field: 'buyerGroup', headerName: 'Buyer Group', width: 150, editable: true},
  { field: 'location', headerName: 'Location', width: 150, editable: true },
  { field: 'effectivityUnit', headerName: 'Effectivity Unit', width: 150, editable: true },
  { field: 'brand', headerName: 'Brand/Product', width: 350, editable: true },
  { field: 'category', headerName: 'Category', width: 150, editable: true },
  { field: 'size', headerName: 'Size', width: 100, editable: true },
  { field: 'orderDate', headerName: 'Order Date', width: 150, editable: true },  
  { field: 'orderQuantity', headerName: 'Order Quantity', width: 150, editable: true },
  { field: 'status', headerName: 'Status', width: 120, editable: true },
  { field: 'dispatchedQuantity', headerName: 'Dispatched Quantity', width: 150, editable: true },
  { field: 'balanceQuantity', headerName: 'Balance Quantity', width: 150, editable: true },
];

export const staticColumnsInvoice: GridColDef[] = [
  { field: 'id', headerName: 'SL. No', width: 15},
  { field: 'eUnit', headerName: 'Effective Unit', width: 80, editable: true },
  { field: 'product', headerName: 'Product', width: 425, editable: true },  
  { field: 'size', headerName: 'Size', width: 140, editable: true },
  { field: 'jar', headerName: 'Jar', width: 90, editable: true },
  { field: 'cap', headerName: 'Cap', width: 90, editable: true },
  { field: 'color', headerName: 'Colour', width: 90, editable: true },
  { field: 'qty', headerName: 'Quantity (as per packaging std)', width: 130, editable: true },
  { field: 'rate', headerName: 'Rate', width: 90, editable: true },
  { field: 'total', headerName: 'Total', flex: 1, editable: false },
];

export const usersColumn: GridColDef[] = [
  { field: "username", headerName: "User Name", flex: 1 },
  { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
  { field: "password", headerName: "Password", flex: 1 },
  { field: "status", headerName: "Status", flex: 1 },
  { field: "role", headerName: "Role", flex: 1 },
  { field: "buyers", headerName: "Buyers", flex: 1 },
];

export const buyersColumn: GridColDef[] = [
  { field: 'username', headerName: 'buyerName', editable: true, flex: 1},
  { field: 'outstanding', headerName: 'Outstanding', editable: true, flex: 1 },
  { field: 'dueDate', headerName: 'Due Date', editable: true, flex: 1 },
  { field: 'message', headerName: 'Message', editable: true, flex: 1 },
];

export const remarkOptions = [
  "Urgent Order",
  "Regular Order",
  "Special Request",
  "Priority Delivery",
  "Standard Delivery",
  "Custom Package Required",
  "No Special Instructions",
];

export const processExcelDataForUser = (excelData: any[]): UserData[] => {
  const usersData: UserData[] = [];
  const map = new Map<string, UserData>();

  excelData.forEach((entry: any) => {
    const key = `${entry.phoneNumber}-${entry.username}-${entry.password}-${entry.role}-${entry.status}`;

    if (!map.has(key)) {
      // Create a new user object without buyers if role is USER or SUPER_ADMIN
      const user: UserData = {
        username: entry.username,
        phoneNumber: entry.phoneNumber.toString(),
        password: entry.password,
        role: entry.role,
        status: entry.status
      };

      // Add buyers array only if role is not USER or SUPER_ADMIN
      if (entry.role === "KAM") {
        if (entry?.buyers) {
          user.buyers = entry?.buyers;
        }
      }

      map.set(key, user);
    } else {
      // If buyers array exists, push to it
      const user = map.get(key);
      if (user && user.buyers && !user.buyers.includes(entry.buyers)) {
        user.buyers.push(entry.buyers);
      }
    }
  });
  // Convert the map to an array
  map.forEach((value) => usersData.push(value));
  return usersData;
}

export const processExcelDataForMessage = (excelData: any[]):  UserMessage[] => {
  const usersData: UserMessage[] = [];
  const map = new Map<string, UserMessage>();

  excelData.forEach((entry: any) => {
    const key = `${entry.phoneNumber}-${entry.outstanding}-${entry.dueDate}-${entry.message}`;

    if (!map.has(key)) {
      // Create a new user object without buyers if role is USER or SUPER_ADMIN
      const user: UserMessage = {
        outstanding: entry.outstanding,
        dueDate: entry?.dueDate ?? "" ,
        phoneNumber: entry.phoneNumber.toString(),
        message: entry.message,
      };

      map.set(key, user);
    }
  });
  // Convert the map to an array
  map.forEach((value) => usersData.push(value));
  return usersData;
}

export const processExcelDataForBuyers = (excelData: any[]):  BuyersData[] => {
  const usersData: BuyersData[] = [];
  const map = new Map<string, BuyersData>();

  excelData.forEach((entry: any) => {
    const key = `${entry.buyerGroup}`;

    if (!map.has(key)) {
      // Create a new user object without buyers if role is USER or SUPER_ADMIN
      const user: BuyersData = {
        buyerGroup: entry.buyerGroup,
        brand: [entry.brand]
      };
      map.set(key, user);
    } else {
      const user = map.get(key);
      user?.brand?.push(entry.brand);
    }
  });
  // Convert the map to an array
  map.forEach((value) => usersData.push(value));
  return usersData;
}

export const extractExcelData = (result: ArrayBuffer, sheetName: string) => {
  const data = new Uint8Array(result);
  const workbook = XLSX.read(data, { type: "array" });

  // Assuming the first sheet in the workbook contains the data
  const worksheet = workbook.Sheets[sheetName];

  // Convert the sheet to a JSON array
  const excelData = XLSX.utils.sheet_to_json(worksheet);
  return excelData;
}

export interface Row {
  id: number;
  eUnit: string;
  site: string,
  size: string;
  description: string;
  color: string;
  qty: string;
  rate: string;
  total: number; // Ensure this is always a number
}

export const invoiceData: Row = {
    id: 1,
    eUnit: "",
    site: "",
    size: "",
    description: "",
    color: "",
    qty: "",
    rate: "",
    total: 0,
  }


  export const freightOptions = [
    { value: 'FP1 - Freight will be paid & arranged by customer', label: 'FP1 - Freight will be paid & arranged by customer' },
    { value: 'FP2 - Freight will be paid & arranged by Moldtek', label: 'FP2 - Freight will be paid & arranged by Moldtek' },
    { value: 'FP3 - Freight will be paid by customer and transport suggested by client', label: 'FP3 - Freight will be paid by customer and transport suggested by client' },
    { value: 'FP4 - Freight will be paid by Customer & vehicle by MTPL', label: 'FP4 - Freight will be paid by Customer & vehicle by MTPL' },
    { value: 'FP5 - Freight will be paid by MTPL & transport suggested by client', label: 'FP5 - Freight will be paid by MTPL & transport suggested by client' },
    { value: 'FP6 - Freight will be born by customer & vehicle till local transport by MTPL', label: 'FP6 - Freight will be born by customer & vehicle till local transport by MTPL' },
  ];


  export const customStyles = {
    menu: (base: any) => ({
      ...base,
      whiteSpace: 'normal', // Allow wrapping for the dropdown
    }),
    option: (base: any) => ({
      ...base,
      whiteSpace: 'normal', // Allow wrapping for the options
      wordBreak: 'break-word', // Break long words
      padding: '10px', // Add padding for better readability
    }),
    control: (base: any) => ({
      ...base,
      whiteSpace: 'normal', // Allow multiline selected option
    }),
    singleValue: (base: any) => ({
      ...base,
      whiteSpace: 'normal', // Allow wrapping for selected value
      wordBreak: 'break-word',
    }),
  };