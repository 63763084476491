import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import db from "../../firebase";
import { getAuthFromLocal } from "../../utils/storage";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { StatusDropdown } from "../../component/atom/StatusDropdown";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const Messages = () => {
  const user = getAuthFromLocal();
  // Columns configuration
  const columns = [
    {
      field: "createdAt",
      headerName: "Time",
      width: 130,
      valueGetter: (params: any) =>
        params && params !== "N/A"
          ? new Date(params).toLocaleString(undefined, {
              dateStyle: "short", // Displays the date (e.g., MM/DD/YYYY)
              timeStyle: "short", // Displays the time (e.g., 10:45 PM)
            })
          : "N/A",
    },
    {
      field: "ticketNumber",
      headerName: "Ticket Number",
      width: 120,
      valueGetter: (params: any) => params || "Not Assigned",
    },
    { field: "buyer", headerName: "Buyer", width: 200 },
    { field: "sender", headerName: "Sender", width: 200 },
    {
      field: "message",
      headerName: "Message",
      width: 200,
      renderCell: (params: any) => (
        <div
          style={{
            whiteSpace: "normal", // Allow text to wrap
            wordBreak: "break-word", // Break long words if necessary
            fontSize: "0.875rem", // Smaller font size for readability
            lineHeight: "1.2", // Tighten the line spacing
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "url",
      headerName: "Attachment",
      width: 130,
      renderCell: (params: any) =>
        params?.value ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const url = params.value;
              window.open(url, "_blank");
            }}
          >
            Download
          </Button>
        ) : (
          <span>No Attachment</span>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params: any) => {
        const status = params?.row?.status || "unknown";
        const color =
          status === "open"
            ? "#FFD700"
            : status === "start"
            ? "#87CEFA"
            : status === "submitted"
            ? "#E71D36"
            : status === "done"
            ? "#32CD32"
            : "#D3D3D3";

        return (
          <span
            style={{
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: color,
              color: "white",
              fontWeight: "bold",
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      field: "ticketAge",
      headerName: "Ticket Age",
      width: 130,
      renderCell: (params: any) => {
        const ticketTimestamp = params?.row.completeDate ?? params.row.timeStamp; // Assuming `timestamp` is in ISO format

        if (!ticketTimestamp) {
          // Handle missing timestamp
          return <span style={{ color: "gray" }}>N/A</span>;
        }

        const now = dayjs();
        const ticketTime = dayjs(ticketTimestamp);

        const hoursAgo = now.diff(ticketTime, "hour");
        const daysAgo = now.diff(ticketTime, "day");

        // Calculate how to display age
        let displayText = "";
        if (hoursAgo < 1) {
          displayText = `${now.diff(ticketTime, "minute")} minutes ago`;
        } else if (hoursAgo < 24) {
          displayText = `${hoursAgo} hours ago`;
        } else {
          displayText = `${daysAgo} days ago`;
        }

        // Determine background color for old tickets
        const isOlderThan24Hours = hoursAgo >= 24;
        const cellStyle: React.CSSProperties = {
          backgroundColor: isOlderThan24Hours ? "red" : "transparent",
          color: isOlderThan24Hours ? "white" : "inherit",
          padding: "8px",
          borderRadius: "4px",
          fontWeight: isOlderThan24Hours ? "bold" : "normal",
          textAlign: "center", // Fixed typing issue
        };

        return <span style={cellStyle}>{displayText}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 190,
      renderCell: (params: any) =>
        user?.role === "KAM" || user?.role === "SUPER_ADMIN" ? (
          <StatusDropdown
            currentStatus={params.row.status}
            onStatusChange={(newStatus) =>
              handleChangeStatus(params.row, newStatus)
            }
            isAdmin={user?.role === "SUPER_ADMIN"}
          />
        ) : null,
    },
  ];

  const [data, setData] = useState<any>([]);
  const [allData, setAllData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user?.role === "KAM") {
      fetchKAMBuyers();
    } else {
      fetchData(false, []);
    }
  }, []);

  const fetchKAMBuyers = async () => {
    try {
      const collectionRef = collection(db, "users"); // Replace with your collection name
      const q = query(
        collectionRef,
        where("phoneNumber", "==", user?.phoneNumber)
      ); // Query for the document where KAM equals "deepak"
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0]; // Since KAM is unique, take the first (and only) document
        fetchData(true, doc.data().buyers);
      }
    } catch (e) {
      console.error("Error fetching data: ", e);
    } finally {
      setLoading(false);
    }
  };

  async function updateTicketStatus(ticketNumber: any, newStatus: any) {
    try {
      // Query the messages collection for the document with the specified ticketNumber
      const q = query(
        collection(db, "messages"),
        where("ticketNumber", "==", ticketNumber)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Firestore will return all matching documents; update the first match
        const docRef = querySnapshot.docs[0].ref;

        // Determine the field to update based on the new status
        const updates = {
          status: newStatus,
          ...(newStatus === "done"
            ? { completeDate: new Date().toISOString() }
            : {
                [newStatus === "start" ? "startDate" : "submitDate"]:
                  new Date().toISOString(),
              }),
        };

        // Update the document with the determined fields
        await updateDoc(docRef, updates);

        console.log(`Ticket #${ticketNumber} status updated to ${newStatus}`);
      } else {
        console.log(`No ticket found with Ticket Number: ${ticketNumber}`);
      }
    } catch (error) {
      console.error("Error updating ticket status:", error);
    }
  }

  const handleChangeStatus = (ticket: any, newStatus: string) => {
    updateTicketStatus(ticket.ticketNumber, newStatus.toLowerCase())
      .then(() => {
        // Optionally refresh data
        if (user?.role === "KAM") {
          fetchKAMBuyers();
        } else {
          fetchData(false, []);
        }
        toast.success("Status updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        alert("Failed to update status. Please try again.");
      });
  };

  const fetchData = async (isKAM = false, buyers: any) => {
    try {
      const q = query(
        collection(db, "messages"),
        where("status", "!=", "done"),
        orderBy("status"), 
        orderBy("timestamp", "desc")
      );

      const querySnapshotAll = await getDocs(collection(db, "messages"));
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc, i) => ({
        id: i + 1,
        buyer: doc.data().buyer || "Unknown Buyer",
        url: doc.data().url || null,
        status: doc.data().status || "unknown",
        completeDate: doc.data()?.completeDate || null,
        createdAt: doc.data().timestamp || "N/A", // Renamed timestamp
        timeStamp: doc.data().timestamp || null, // Renamed timestamp
        ticketNumber: doc.data().ticketNumber || "Not Assigned",
        message: doc.data().message,
        sender: doc.data().sender,
      }));

      const allData = querySnapshotAll.docs.map((doc, i) => ({
        id: i + 1,
        buyer: doc.data().buyer || "Unknown Buyer",
        url: doc.data().url || null,
        status: doc.data().status || "unknown",
        createdAt: new Date(doc.data().timestamp).toLocaleString(undefined, {
          dateStyle: "short", // Displays the date (e.g., MM/DD/YYYY)
          timeStyle: "short", // Displays the time (e.g., 10:45 PM)
        }),
        completeDate: new Date(doc.data()?.completeDate).toLocaleString(undefined, {
          dateStyle: "short", // Displays the date (e.g., MM/DD/YYYY)
          timeStyle: "short", // Displays the time (e.g., 10:45 PM)
        }),
        startDate: new Date(doc.data()?.startDate).toLocaleString(undefined, {
          dateStyle: "short", // Displays the date (e.g., MM/DD/YYYY)
          timeStyle: "short", // Displays the time (e.g., 10:45 PM)
        }),
        submitDate: new Date(doc.data()?.submitDate).toLocaleString(undefined, {
          dateStyle: "short", // Displays the date (e.g., MM/DD/YYYY)
          timeStyle: "short", // Displays the time (e.g., 10:45 PM)
        }),
        ticketNumber: doc.data().ticketNumber || "Not Assigned",
        message: doc.data().message,
      }));

      setAllData(allData);

      let filtered = isKAM
        ? fetchedData.filter((a) => buyers.includes(a.buyer) || a.sender === user?.username)
        : fetchedData;

      setData(filtered);
    } catch (e) {
      console.error("Error fetching data: ", e);
    } finally {
      setLoading(false);
    }
  };

  const handleExportToExcel = async (tableData: any[], columns: any) => {
    const dataWithoutStaticColumns = tableData.map(
      ({ id, actions, ticketAge, ...rest }) => rest
    );

    const additionalFields = ['completeDate', 'startDate', 'submitDate'];
    
    const filteredHeaders = [
      ...columns.filter((col: any) => !['id', 'actions', 'ticketAge'].includes(col.field)).map((col: any) => col.field),
      ...additionalFields,
    ];
    const worksheet = XLSX.utils.json_to_sheet(dataWithoutStaticColumns, {
      header: filteredHeaders.map((col: any) => col.field),
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert workbook to an array buffer (binary data)
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Create a Blob from the binary data and use FileSaver to trigger a download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "messages.xlsx"); // This will trigger the download
  };

  return (
    <Box
      className="container mx-auto"
      sx={{ height: 400, width: "100%", p: 4 }}
    >
      {user?.role !== "USER" && (
        <Button
          onClick={() => handleExportToExcel(allData, columns)}
          variant="contained"
          component="label"
          sx={{ mb: 2 }}
        >
          Export Messages
        </Button>
      )}
      <DataGrid
        pageSizeOptions={[20, 30, 40, { value: -1, label: "All" }]}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        style={{
          height: "80vh", // Full height of the viewport
          overflow: "hidden", // Prevent unnecessary scrollbars
        }}
        disableVirtualization
        loading={loading}
        rows={data || []}
        columns={columns}
        getRowId={(row) => row.id}
        sx={{
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
            backgroundColor: "#566270",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important",

            // Adjust as needed
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
          },
        }}
      />
    </Box>
  );
};

export default Messages;
