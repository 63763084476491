import { useState } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";

export const StatusDropdown = ({
  currentStatus,
  onStatusChange,
  isAdmin,
}: {
  currentStatus: string;
  onStatusChange: (status: string) => void;
  isAdmin: boolean;
}) => {
  const [status, setStatus] = useState<string>(currentStatus);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    onStatusChange(newStatus); // Callback to update status in Firestore
  };

  return (
    <Select
      value={status}
      onChange={handleStatusChange}
      disableUnderline // Remove underline
      variant="standard" // Use a simple style
      sx={{
        fontSize: "inherit", // Match DataGrid row font size
        color: "inherit", // Match DataGrid row text color
        backgroundColor: "transparent", // Remove background color
        boxShadow: "none", // Remove box-shadow
        padding: 0, // Remove padding
        border: "none", // Remove border completely
        width: "100%", // Ensure it fills the column width
        "& .MuiSelect-select": {
          paddingRight: "28px", // Add space for the dropdown icon
          display: "flex",
          alignItems: "center",
        },
        "& .MuiSelect-icon": {
          right: "8px", // Position the icon to the far right
          top: "50%", // Center align vertically
          transform: "translateY(-50%)", // Adjust for perfect centering
          pointerEvents: "none", // Prevent icon from blocking clicks
        },
        "&:focus": {
          outline: "none", // Remove the blue focus outline
        },
        "&:hover": {
          backgroundColor: "transparent", // Ensure no hover background
        },
      }}
    >
      <MenuItem value="start">Start</MenuItem>
      <MenuItem value="submitted">Submit for validate</MenuItem>
      {isAdmin && <MenuItem value="done">Done</MenuItem>}
    </Select>
  );
};
