import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { fetchAndParseExcel, sheetNames, staticColumnsSellOrder } from "../../utils";
import { doc, getDoc, setDoc } from "firebase/firestore";
import db, { uploadDocument } from "../../firebase";
import { getAuthFromLocal } from "../../utils/storage";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const SellTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const user = getAuthFromLocal();

  const [isSellUploading, setIsSellUploading] = useState(false);

  const setDocToStore = async (url) => {
    const fileDocRef = doc(db, "files", "sellOrderFile"); // Adjust path and document ID as needed
    await setDoc(fileDocRef, {
      url: url,
    });
  };

  const handleSellOrderUpload = async (
    event
  ) => {
    setIsSellUploading(true);
    const file = event.target.files?.[0];
    if (file) {
      const url = await uploadDocument(file);
      setDocToStore(url);
      setIsSellUploading(false);
      toast.success("Sale order added");
    }
  };

  useEffect(() => {
    loadDataFromFirestore();
  }, []);

  const fetchDownloadURL = async () => {
    try {
      // Get the document containing the download URL
      const fileDocRef = doc(db, "files", "sellOrderFile"); // Adjust path if needed
      const fileDoc = await getDoc(fileDocRef);

      if (fileDoc.exists()) {
        const data = fileDoc.data();
        return data.url; // Return the download URL
      } else {
        console.error("No file found in Firestore");
        return null;
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
      return null;
    }
  };

  const loadDataFromFirestore = async (url = "") => {
    const downloadURL = url !== "" ? url : await fetchDownloadURL();

    if (downloadURL) {
      const jsonData = await fetchAndParseExcel(downloadURL);

      if (jsonData && jsonData.length > 1) {
        const bodyData = jsonData.slice(1);
        let rowsData = bodyData.map((row, index) => {
          const rowData = { id: index + 1 };

          jsonData[0].forEach((header, index) => {
            let value = row[index] || "";

            if (
              (header === "Call up Date") && typeof value === "number" &&
              !isNaN(value) && value > 0
            ) {
              const date = new Date((value - 25569) * 86400 * 1000);
              if (!isNaN(date.getTime())) {
                value = date.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                });
              }
            }

            rowData[header] = value;
          });

          return rowData;
        });

        console.log(rowsData);

        if (user && user.role === "KAM") {
          rowsData = rowsData.filter(
            (a) =>
              a.KAM_ph &&
              a.KAM_ph.toString().trim() === user.phoneNumber.toString().trim()
          );
        }
        const data = [];
        rowsData.forEach((item) => {
          let callupDate = "";
          const date = new Date((item?.callup_date - 25569) * 86400 * 1000);
          if (!isNaN(date.getTime())) {
            callupDate = date.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "2-digit",
            });
          }
          data.push({ ...item, callup_date: callupDate });
        });

        setTableData(data);
        setIsLoading(false);
      }
    }
  };

  const handleExportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetNames.SELL_ORDER);

    XLSX.writeFile(wb, "sellOrder.xlsx");
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
      <Button
        disabled={isSellUploading || user?.role === "KAM"}
        variant="contained"
        component="label"
      >
        {isSellUploading ? "Uploading..." : "Upload Sale Order"}
        <input
          type="file"
          hidden
          onChange={handleSellOrderUpload}
          accept=".xlsx, .xls"
        />
      </Button>
      {(user?.role !== "USER") && (
        <Button variant="contained" className="w-[200px]" onClick={handleExportToExcel}>
          Export Data
        </Button>
      )}
      </div>
      <DataGrid
        pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          columns: {
            columnVisibilityModel: {
              KAM: false,
            },
          },
        }}
        rows={tableData}
        columns={staticColumnsSellOrder}
        getRowId={(row) => row.id}
        loading={isLoading}
        sx={{
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
            backgroundColor: "#566270",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important",

            // Adjust as needed
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
            fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
          },
        }}
      />
    </div>
  );
};

export default SellTable;
