export const BASE_URL = "/";
const ROUTE_URLS = {
  HOME: BASE_URL,
  LOGIN: "/login",
  ORDERS: "/orders",
  ORDER_STATUS: "/order-status", 
  USERS: "/users", 
  SELL_ORDER: 'sale-order',
  INVOICE: 'invoice',
  AG_DEMO: 'ag-demo',
  MESSAGE: 'messages',
  ADMIN: 'admin',
};

export default ROUTE_URLS;
