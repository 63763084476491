import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  customStyles,
  fetchAndParseExcel,
  freightOptions,
  invoiceData,
  Row,
} from "../../utils";
import { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import db, { uploadDocumentForMaster } from "../../firebase";
import ReactSelect from "react-select";
import { Button, MenuItem, Select } from "@mui/material";
import { getAuthFromLocal } from "../../utils/storage";

import { toast } from "react-toastify";
import autoTable from "jspdf-autotable";
import { ProductSearchModal } from "../../component/atom/ProductSearchModal";

type InvoiceData = {
  id: string; // Buyer ID
  [key: string]: string[] | string; // Dynamic user names with arrays of strings
};

const formatDate = (inputDate: Date) => {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" }); // Dec
  const year = date.getFullYear();
  return `${day} - ${month} - ${year}`;
};

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0, // No decimals
  }).format(value);
};

const today = new Date();
const CurrentDate = formatDate(today); // Format: YYYY-MM-DD

const Invoice = () => {
  const invoiceArray = Array.from({ length: 15 }, (_, index) => ({
    ...invoiceData, // Spread the default object
    id: index + 1, // Increment id for each object
  }));
  const user = getAuthFromLocal() as any;
  const [data, setData] = useState(invoiceArray);
  const [masterData, setMasterData] = useState<any[]>([]);
  const [masterObj, setMasterObj] = useState<any>({});
  const [buyerData, setBuyerData] = useState<any[]>([]);
  const [sum, setSum] = useState<any>({ totalSum: 0, gstAmount: 0 });
  const [productArray, setProductArray] = useState<any[]>([]);
  const [savedInvoices, setSaveInvoices] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [invoiceName, setInvoiceName] = useState<any>("");

  const [selectedDeliveryTerm, setDeliveryTerm] = useState<any>(null);

  const [isMasterInvoiceData, setIsMasterInvoice] = useState(false);
  const [isMasterCustomerData, setIsMasterCustomer] = useState(false);

  const [billingAddress, setBillingAddress] = useState<any>([]);
  const [deliveryAddress, setDeliveryAddress] = useState<any>([]);
  const [billingValue, setBillingValue] = useState<any>({
    label: "",
    value: "",
    "Business Partner (child)": "",
  });
  const [deliveryValue, setDeliveryValue] = useState<any>({
    label: "",
    value: "",
    "Business Partner (child)": "",
  });
  const [freight, setFreight] = useState<any>(0);
  const [advance, setAdvance] = useState<any>(0);
  const [balanceAmount, setBalanceAmount] = useState<any>(0);

  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    loadDataFromFirestore();
    fetchBuyerGroup();
    loadProducts();
  }, []);

  useEffect(() => {
    fetchInvoicesByKAM();
  }, [masterObj]);

  const isSuperAdmin = user?.role === "SUPER_ADMIN";
  const isKAM = user?.role === "KAM";

  useEffect(() => {
    setBalanceAmount(Number(sum.totalSum) - Number(advance));
  }, [advance, sum, freight]);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    // Check if all rows with a description also have qty and rate
    const isEligible = data.every((item) => {
      // If description is empty, it's fine (row not considered)
      if (item.description.trim() === "") return true;

      // If description exists, check if qty and rate are also filled
      return item.qty.trim() !== "" && item.rate.trim() !== "";
    });

    setIsButtonEnabled(isEligible);
  }, [data]);

  const fetchBuyerGroup = async () => {
    try {
      // Get the document containing the download URL
      const fileDocRef = collection(db, "buyerGroup"); // Adjust path if needed
      const querySnapshot = await getDocs(fileDocRef);

      const buyerDatas: any = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists() && doc.data()?.brandName)
          buyerDatas.push({ buyer: doc.id, brands: doc.data()?.brandName });
      });
      setBuyerData(buyerDatas);
    } catch (error) {
      console.error("Error fetching download URL:", error);
      return null;
    }
  };

  const handleExport = async (type: string) => {
    const downloadURL = await fetchDownloadURL(type);
    if (downloadURL) {
      window.open(downloadURL);
    }
  };

  const fetchFinalizeData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "invoice"));
      const fetchedData: InvoiceData[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const invoiceRows: {
        Buyer: string;
        User: string;
        Invoice: string;
        [key: string]: string | number; // Metadata fields
      }[] = [];

      const tableRows: any[] = [];

      // Process data for both sheets
      fetchedData.forEach((item) => {
        const { id, ...dataInvoice } = item; // Separate Buyer ID and dynamic keys
        Object.keys(dataInvoice).forEach((user) => {
          const invoices = dataInvoice[user] as unknown as Record<
            string,
            { table: any[]; meta: Record<string, string> }
          >;

          Object.keys(invoices).forEach((invoiceName) => {
            const invoiceData = invoices[invoiceName];
            const { table, meta } = invoiceData;

            // Add to Invoices sheet
            invoiceRows.push({
              Buyer: id,
              User: user,
              Invoice: invoiceName,
              ...meta, // Spread metadata fields
            });

            // Add table data rows
            table.forEach((row) => {
              tableRows.push({
                Invoice: `${id}-${user}-${invoiceName}`,
                Site: row.site || "",
                Description: row.description || "",
                Size: row.size || "",
                eUnit: row.eUnit || "",
                Color: row.color || "",
                Quantity: row.qty || "",
                Rate: row.rate || "",
                Total: row.total || 0,
              });
            });

            // Add an empty row as a separator between invoices
            tableRows.push({
              Invoice: "",
              Site: "",
              Description: "",
              Size: "",
              eUnit: "",
              Color: "",
              Quantity: "",
              Rate: "",
              Total: "",
            }); // Add an empty row as a separator between invoices
          });
        });
      });

      // Create worksheets
      const invoiceSheet = XLSX.utils.json_to_sheet(invoiceRows);
      const tableDataSheet = XLSX.utils.json_to_sheet(tableRows);

      // Create workbook and append worksheets
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, invoiceSheet, "Invoices");
      XLSX.utils.book_append_sheet(workbook, tableDataSheet, "TableData");

      // Export workbook to Excel file
      XLSX.writeFile(workbook, "invoice-data.xlsx");
    } catch (e) {
      console.error("Error fetching data: ", e);
    }
  };

  const fetchDownloadURL = async (filename: string) => {
    try {
      // Get the document containing the download URL
      const fileDocRef = doc(db, "files", filename); // Adjust path if needed
      const fileDoc = await getDoc(fileDocRef);

      if (fileDoc.exists()) {
        const data = fileDoc.data();
        return data.url; // Return the download URL
      } else {
        console.error("No file found in Firestore");
        return null;
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
      return null;
    }
  };

  const loadProducts = async (url = "") => {
    const downloadURL =
      url !== "" ? url : await fetchDownloadURL("masterProductFile");

    if (downloadURL) {
      const jsonData = (await fetchAndParseExcel(downloadURL)) as any[];

      if (jsonData && jsonData.length > 1) {
        const bodyData = jsonData.slice(1);
        let rowsData = bodyData.map((row, index) => {
          const rowData: any = { id: index + 1 };

          jsonData[0].forEach((header: string, index: number) => {
            let value = row[index] || "";

            rowData[header] = value;
          });

          return rowData;
        });

        const filterData = rowsData.filter(
          (a) =>
            a[
              "Include Product Variant Features and Options on transactions"
            ] !== ""
        );

        setBuyerData(filterData);
      }
    }
  };

  const loadDataFromFirestore = async (url = "") => {
    const downloadURL =
      url !== "" ? url : await fetchDownloadURL("masterInvoiceFile");

    if (downloadURL) {
      const jsonData = (await fetchAndParseExcel(downloadURL)) as any[];

      if (jsonData && jsonData.length > 1) {
        const bodyData = jsonData.slice(1);
        let rowsData = bodyData.map((row, index) => {
          const rowData: any = { id: index + 1 };

          jsonData[0].forEach((header: string, index: number) => {
            let value = row[index] || "";

            rowData[header] = value;
          });

          return rowData;
        });

        let filterdata = [];

        if (user?.role === "SUPER_ADMIN") {
          filterdata = rowsData;
        } else {
          filterdata = rowsData.filter(
            (a) =>
              a["Business Partner"] !== "" &&
              a["KAM Ph.no"].toString() === user.phoneNumber.toString()
          );
        }

        // const filteredData = rowsData.filter(
        //   (a) => a["Business Partner"] !== ""
        // );

        const data: any = [];
        filterdata.forEach((item) => {
          data.push({
            ...item,
            value: `${item["Buyer Group"]}`,
            label: `${item["Buyer Group"]}`,
          });
        });

        // Remove duplicates by 'id'
        const uniqueArray = data.filter(
          (item: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t.value === item.value)
        );

        setMasterData(uniqueArray);
        setFilteredData(filterdata);
      }
    }
  };

  // Function to calculate the sum of 'total' column

  const calculateSum = (rows: Row[]): void => {
    const totalSum = rows.reduce(
      (acc, row) => Number(acc) + (Number(row.total) || 0),
      0
    );
    const gstAmount = totalSum * 0.18; // 18% GST
    const totalWithGST = totalSum + gstAmount;
    const totalSums = Number(totalWithGST) + Number(freight);
    setSum({
      totalSum: totalSums.toFixed(2),
      gstAmount: Number(gstAmount).toFixed(2),
    });
  };

  // Recalculate the sum whenever data changes
  useEffect(() => {
    calculateSum(data as Row[]);
  }, [data]);

  const handleBuyerGroupChange = (event: any) => {
    const buyerGroup = event["Buyer Group"];
    const addressData = filteredData
      .filter((a) => a["Buyer Group"] === buyerGroup)
      .map((item) => {
        return {
          ...item,
          value: item["Business Partner"],
          label: item["Street"],
        };
      });

    setBillingAddress(addressData);
    setDeliveryAddress(addressData);
    setInvoiceName(null);
    setDeliveryTerm(null);
    setDeliveryValue({
      label: addressData[0]?.["label"],
      value: addressData[0]?.["value"],
      "Business Partner (child)": addressData[0]?.["Business Partner (child)"],
      City: addressData[0]?.["City"],
    });
    setBillingValue({
      label: addressData[0]?.["label"],
      value: addressData[0]?.["value"],
      "Business Partner (child)": addressData[0]?.["Business Partner (child)"],
      City: addressData[0]?.["City"],
    });

    setMasterObj(event);
    const brands = buyerData.filter((a: any) => a["BuyerGroup"] === buyerGroup);
    if (brands?.length) {
      const arrayOfObjects = brands.map((brand, i) => ({
        ...invoiceData,
        id: i + 1,
        site: brand?.["Site"],
        eUnit: brand?.["Effectivity Unit"] || "",
        size: brand?.["Size_AX"] || "",
        color: brand?.["Colour"] || "",
        description:
          brand?.[
            "Include Product Variant Features and Options on transactions"
          ] || "",
      }));
      setProductArray(arrayOfObjects);
      const invoiceArray = Array.from({ length: 15 }, (_, index) => ({
        ...invoiceData, // Spread the default object
        id: index + 1, // Increment id for each object
      }));
      setData(invoiceArray);
    } else {
      setData(invoiceArray);
    }
  };

  const fetchInvoicesByKAM = async () => {
    const username = user.username;
    const buyerName = masterObj["Buyer Group"];
    if (buyerName) {
      try {
        // Reference to the "invoices" collection
        const invoiceDoc = doc(db, "invoice", buyerName);
        const docSnap = await getDoc(invoiceDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (isKAM) {
            const currentInvoices = data[username];
            const result = Object.keys(currentInvoices).map((invoiceName) => {
              const invoiceData = currentInvoices[invoiceName];
              return {
                invoiceName,
                table: invoiceData.table || [],
                meta: invoiceData.meta || {},
                label: invoiceName,
                value: invoiceName,
              };
            });
            setSaveInvoices(result);
          } else if (isSuperAdmin) {
            const result = Object.keys(data).flatMap((kamUsername: any) => {
              const kamInvoices: any = data[kamUsername];
              return Object.keys(kamInvoices).map((invoiceName: any) => {
                const invoiceData = kamInvoices[invoiceName];
                return {
                  invoiceName: `${invoiceName} (${kamUsername})`, // Append KAM username to invoiceName
                  table: invoiceData.table || [],
                  meta: invoiceData.meta || {},
                  label: `${invoiceName} (${kamUsername})`,
                  value: `${invoiceName} (${kamUsername})`,
                };
              });
            });
            setSaveInvoices(result);
          }
        }
        //
      } catch (error) {
        console.error("Error fetching invoices:", error);
        throw error;
      }
    }
  };

  const generateInvoiceRevision = async (isFinal = false) => {
    const filtered = data.filter((a) => a.description !== "");
    const buyerName = masterObj["Buyer Group"]; // Buyer's name or group
    const buyerKey = user.username; // KAM username
    const today = new Date();
    const year = today.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Month (01-12)
    const day = today.getDate().toString().padStart(2, "0"); // Day (01-31)
    const dateKey = `${day}-${month}-${year}`; // Format: DD-MM-YY

    try {
      // Reference the Firestore document for the buyer
      const docRef = doc(db, "invoice", buyerName);

      // Fetch the existing document
      const docSnap = await getDoc(docRef);

      let currentInvoices: Record<string, any> = {};
      let currentInvoiceNumber = 1;

      if (docSnap.exists()) {
        const data = docSnap.data();
        currentInvoices = data[buyerKey] || {};

        // Extract the latest invoice number
        const lastInvoiceKey = Object.keys(currentInvoices).pop();
        if (lastInvoiceKey) {
          const lastInvoiceMatch = lastInvoiceKey.match(/in-(\d+)/);
          if (lastInvoiceMatch) {
            currentInvoiceNumber = parseInt(lastInvoiceMatch[1], 10);
          }
        }
      } else {
        // If no document exists, initialize currentInvoices and start with the first invoice
        currentInvoices = {};
        currentInvoiceNumber = 1;
      }

      let nextInvoiceKey: any;
      const lastInvoiceKey = Object.keys(currentInvoices).pop() || "";
      const lastInvoiceIsFinal = lastInvoiceKey.includes("-final");

      if (lastInvoiceIsFinal) {
        // Start a new invoice series (e.g., "in-2")
        currentInvoiceNumber++;
        nextInvoiceKey = `${dateKey}/in-${currentInvoiceNumber}`;
      } else if (!lastInvoiceKey) {
        // If there are no previous invoices, start with "in-1"
        nextInvoiceKey = `${dateKey}/in-${currentInvoiceNumber}`;
      } else {
        // Continue revising the current invoice
        const lastRevisionMatch = lastInvoiceKey.match(/in-\d+(\.\d+)?/);
        let nextRevision = 1;

        if (lastRevisionMatch) {
          const lastRevision = lastInvoiceKey.split(".")[1];
          nextRevision = lastRevision ? parseInt(lastRevision, 10) + 1 : 2;
        }

        nextInvoiceKey = `${dateKey}/in-${currentInvoiceNumber}.${nextRevision}`;
      }

      // Append "-final" if this is the final revision
      if (isFinal) {
        nextInvoiceKey += "-final";
      }

      setInvoiceName({ value: nextInvoiceKey });

      // Add table data for the new invoice or revision
      currentInvoices[nextInvoiceKey] = {
        table: filtered,
        meta: {
          billing_customer_code: billingValue.value,
          billing_customer_name: billingValue["Business Partner (child)"],
          billing_address: billingValue?.label,
          billing_city: billingValue?.["City"],

          delivery_customer_code: deliveryValue.value,
          delivery_customer_name: deliveryValue["Business Partner (child)"],
          delivery_address: deliveryValue?.label,
          delivery_city: deliveryValue?.["City"],

          payment_terms: masterObj["Pay terms_BP"],
          delivery_terms: selectedDeliveryTerm?.label,

          freight,
          advance,
        },
      };

      // Remove non-finalized revisions of the previous series
      const filteredInvoices = Object.keys(currentInvoices).reduce(
        (acc, key) => {
          const isCurrentSeries = key.startsWith(
            `${dateKey}/in-${currentInvoiceNumber}`
          );
          if (!isCurrentSeries || key === nextInvoiceKey) {
            acc[key] = currentInvoices[key];
          }
          return acc;
        },
        {} as Record<string, any>
      );

      if (docSnap.exists()) {
        // Update Firestore for existing document
        await updateDoc(docRef, { [buyerKey]: filteredInvoices });
        console.log(
          "Invoice and table data updated successfully:",
          filteredInvoices
        );
      } else {
        // Create a new document if it doesn't exist
        await setDoc(docRef, { [buyerKey]: filteredInvoices });
        console.log(
          "New document created with the first invoice:",
          filteredInvoices
        );
      }

      generatePDF();
    } catch (error) {
      console.error("Error generating invoice revision:", error);
      toast.error("Error generating invoice!");
    }
  };

  const captureElementAsImage = async (element: HTMLElement) => {
    // 2. Create canvas from element
    const canvas = await html2canvas(element, { scale: 2, useCORS: true });
    // 3. Convert to Base64 image
    return canvas.toDataURL("image/png");
  };

  const generatePDF = async () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let currentY = 10;

    // This is the width in px we want to “force” our container to match
    // so it fills the PDF page in width.
    const DESIRED_PX_WIDTH = 1200;

    // --- 1) TOP CONTENT ---
    let originalTopWidth = "";
    if (topRef.current) {
      // Save original width
      originalTopWidth = topRef.current.style.width;
      // Force container to a fixed width
      topRef.current.style.width = `${DESIRED_PX_WIDTH}px`;

      // Wait one tick for layout
      await new Promise((r) => setTimeout(r, 0));

      // Now capture
      const topImageData = await captureElementAsImage(topRef.current);

      // Restore original width
      topRef.current.style.width = originalTopWidth;

      // Calculate image dimensions
      const topImgProps = doc.getImageProperties(topImageData);
      const topRatio = topImgProps.width / topImgProps.height;

      const topImageWidth = pageWidth; // Fill PDF page width
      const topImageHeight = pageWidth / topRatio;

      doc.addImage(
        topImageData,
        "PNG",
        0,
        currentY,
        topImageWidth,
        topImageHeight
      );
      currentY += topImageHeight + 10;
    }

    // --- 2) autoTable for the Data (Table) ---
    const autoTableColumns = staticColumnsInvoice.map((col) => ({
      header: col.headerName || "",
      dataKey: col.field,
    }));

    const filtered = data.filter((a) => a.description !== "");

    autoTable(doc, {
      columns: autoTableColumns,
      body: filtered,
      startY: currentY,
      margin: { left: 6, right: 6 },
      styles: {
        fontSize: 10,
        cellPadding: 4,
        textColor: [0, 0, 0], // Black font color
        lineColor: [0, 0, 0], // Black border color
        lineWidth: 0.1, // Thin border lines
      },
      headStyles: { fillColor: [117, 79, 68], textColor: [255, 255, 255] }, // Optional header style
      didParseCell: (data) => {
        if (data.section === "body") {
          data.cell.styles.fillColor = [253, 219, 201]; // Light peach color for all rows (RGB for #FDDBC9)
        }
      },
      didDrawPage: (data: any) => {
        currentY = data.cursor.y;
      },
    });

    const finalY = (doc as any).lastAutoTable?.finalY || currentY;
    currentY = finalY + 10;

    // --- 3) BOTTOM CONTENT ---
    let originalBottomWidth = "";
    if (bottomRef.current) {
      // Save original width
      originalBottomWidth = bottomRef.current.style.width;
      // Force the width before capturing
      bottomRef.current.style.width = `${DESIRED_PX_WIDTH}px`;

      // Wait a tick
      await new Promise((r) => setTimeout(r, 0));

      // Capture bottom section
      const bottomImageData = await captureElementAsImage(bottomRef.current);

      // Restore original width
      bottomRef.current.style.width = originalBottomWidth;

      // Check if it fits on this page
      const bottomImgProps = doc.getImageProperties(bottomImageData);
      const bottomRatio = bottomImgProps.width / bottomImgProps.height;

      const bottomImageWidth = pageWidth; // Fill PDF page width
      const bottomImageHeight = pageWidth / bottomRatio;

      // If there's not enough space for bottom content, add a new page
      if (currentY + bottomImageHeight > pageHeight) {
        doc.addPage();
        currentY = 10;
      }

      // Add bottom content image
      doc.addImage(
        bottomImageData,
        "PNG",
        0,
        currentY,
        bottomImageWidth,
        bottomImageHeight
      );
      currentY += bottomImageHeight + 10;
    }

    // --- 4) Save the PDF ---
    doc.save("invoice.pdf");
    toast.success("Invoice generated successfully!");
  };

  const handleFreight = (e: any) => {
    const value = e.target.value;
    const totalSum = data.reduce(
      (acc, row) => Number(acc) + (Number(row.total) || 0),
      0
    );
    setSum({
      ...sum,
      totalSum: Number(value) + Number(sum.gstAmount) + Number(totalSum),
    });
    setFreight(value);
  };

  const handleOpenModal = (rowId: any) => {
    setSelectedRowId(rowId);
    setModalOpen(true);
  };

  const handleSelectProduct = (product: any) => {
    handleProductChange(selectedRowId, product.description);
  };

  const handleAddNewProduct = (description: string) => {
    const newProduct = {
      description,
      color: "",
      size: "",
      eUnit: "",
    };
    productArray.push(newProduct); // Add to product list
    handleProductChange(selectedRowId, description); // Update the row
  };

  const handleProductChange = (rowId: any, value: any) => {
    const product = productArray.find((item) => item.description === value);

    if (product) {
      const updatedRows = data.map((row) =>
        row.id === rowId
          ? {
              ...row,
              description: product.description,
              color: product.color,
              size: product.size,
              eUnit: product.eUnit,
              qty: "",
              rate: "",
              total: 0,
            }
          : row
      );
      setData(updatedRows);
    }
  };

  const setDocToStoreMaster = async (url: string, name: string) => {
    const fileDocRef = doc(db, "files", name); // Adjust path and document ID as needed
    await setDoc(fileDocRef, {
      url: url,
    });
  };

  const handleMasterUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsMasterInvoice(true);
    const file = event.target.files?.[0];
    if (file) {
      const url = await uploadDocumentForMaster(file, "masterInvoice");
      setDocToStoreMaster(url, "masterInvoiceFile");
      setIsMasterInvoice(false);
      toast.success("Master data added");
    }
  };

  const handleMasterProductUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsMasterCustomer(true);
    const file = event.target.files?.[0];
    if (file) {
      const url = await uploadDocumentForMaster(file, "masterProduct");
      setDocToStoreMaster(url, "masterProductFile");
      setIsMasterCustomer(false);
      toast.success("Master customer product data added");
    }
  };

  const handleInvoiceSelect = (event: any) => {
    // Merge the specific data rows into the array
    const invoiceArray = Array.from({ length: 15 }, (_, index) => {
      // Check if there's a specific data row for the current index
      const existingRow = event?.table.find((row: any) => row.id === index + 1);
      return existingRow || { ...invoiceData, id: index + 1 };
    });

    setData(invoiceArray);
    setFreight(event?.meta?.freight);
    setAdvance(event?.meta?.advance);
    setInvoiceName(event);
    setDeliveryTerm({
      label: event?.meta?.delivery_terms,
      value: event?.meta?.delivery_terms,
    });
    setDeliveryValue({
      label: event?.meta?.delivery_address,
      value: event?.meta?.delivery_customer_code,
      "Business Partner (child)": event?.meta?.delivery_customer_name,
      City: event?.meta?.delivery_city,
    });
    setBillingValue({
      label: event?.meta?.billing_address,
      value: event?.meta?.billing_customer_code,
      "Business Partner (child)": event?.meta?.billing_customer_name,
      City: event?.meta?.billing_city,
    });
  };

  const staticColumnsInvoice: GridColDef[] = [
    {
      field: "id",
      headerName: "SL. No",
      flex: 1,
      maxWidth: 40,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "site",
      headerName: "Site",
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const siteOptions = Array.from(
          { length: 15 },
          (_, i) => `Unit-${i + 1}`
        );

        return (
          <Select
            value={params.row.site || ""}
            onChange={(event) =>
              handleSiteChange(params.row.id, event.target.value)
            }
            size="small"
            fullWidth
            disableUnderline // Removes the default underline
            variant="standard" // Makes the Select look flat
            sx={{
              "& .MuiSelect-icon": {
                display: "none", // Hides the dropdown icon
              },
              "& .MuiSelect-select": {
                padding: "0", // Removes padding inside the Select
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none", // Removes the outline when focused
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none", // Removes the outline when hovered
              },
            }}
          >
            {siteOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 280,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button
          className="outline-none border-none w-full h-full"
          sx={{
            fontSize: "11px", // Make text smaller
            lineHeight: 1.2, // Remove extra line height
            padding: "1px", // Remove padding
            textAlign: "left", // Align text to the left if needed
            overflow: "hidden", // Prevent overflow
            textOverflow: "ellipsis", // Add ellipsis for long text
            whiteSpace: "normal", // Allow text wrapping
          }}
          onClick={() => handleOpenModal(params.row.id)}
        >
          {params.row.description}
        </Button>
      ),
    },
    {
      field: "eUnit",
      headerName: "Effective Unit",
      width: 80,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
    },
    {
      field: "size",
      headerName: "Size",
      width: 120,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
    },
    {
      field: "color",
      headerName: "Colours (Jar, Cap, Handle)",
      width: 90,
      disableColumnMenu: true,
      sortable: false,
      editable: true,
    },
    {
      field: "qty",
      headerName: "Qty",
      //    headerName: "Quantity (as per packaging std)",
      width: 80,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "rate",
      headerName: "Rate",
      width: 70,
      editable: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "total",
      headerName: "Total",
      width: 135,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  // Handler for "Site" dropdown change
  const handleSiteChange = (rowId: any, value: string) => {
    const updatedRows = data.map((row) =>
      row.id === rowId ? { ...row, site: value } : row
    );
    setData(updatedRows);
  };

  return (
    <div className="py-2 container mx-auto">
      <div className="flex flex-col lg:flex-row gap-4 px-4 mb-2">
        {isSuperAdmin && (
          <Button
            disabled={isMasterInvoiceData}
            variant="contained"
            component="label"
          >
            {isMasterInvoiceData
              ? "Uploading..."
              : "Upload Master Data for Proforma Invoice"}
            <input
              type="file"
              hidden
              onChange={handleMasterUpload}
              accept=".xlsx, .xls"
            />
          </Button>
        )}
        {isSuperAdmin && (
          <Button
            disabled={isMasterCustomerData}
            variant="contained"
            component="label"
          >
            {isMasterCustomerData
              ? "Uploading..."
              : "Upload Master Data for Customer Product"}
            <input
              type="file"
              hidden
              onChange={handleMasterProductUpload}
              accept=".xlsx, .xls"
            />
          </Button>
        )}
        {isSuperAdmin && (
          <Button
            onClick={() => handleExport("masterInvoiceFile")}
            variant="contained"
          >
            Export Proforma invoice
          </Button>
        )}
        {isSuperAdmin && (
          <Button
            onClick={() => handleExport("masterProductFile")}
            variant="contained"
          >
            Export Customer Product
          </Button>
        )}
      </div>
      <div className="px-4 pb-2 flex w-full justify-between gap-4 py-2 flex-col md:flex-row">
        <div className="flex gap-4 flex-col lg:flex-row">
          <ReactSelect
            placeholder="select buyers"
            options={masterData}
            className="w-[300px]"
            onChange={handleBuyerGroupChange}
          />

          <ReactSelect
            placeholder="select invoices"
            options={savedInvoices}
            value={invoiceName}
            className="w-[300px]"
            onChange={handleInvoiceSelect}
          />
        </div>
        <div className="flex gap-4">
          <Button
            disabled={!isButtonEnabled || !selectedDeliveryTerm?.value}
            onClick={() => generateInvoiceRevision()}
            variant="contained"
          >
            Generate PROFORMA
          </Button>
          <Button
            disabled={!isButtonEnabled || !selectedDeliveryTerm?.value}
            onClick={() => generateInvoiceRevision(true)}
            variant="contained"
          >
            Finalize Invoice
          </Button>
          <Button onClick={fetchFinalizeData} variant="contained">
            Export Finalize invoices
          </Button>
        </div>
      </div>
      <div className="text-sm">
        {/* Render top content */}
        <div className="px-4 " ref={topRef}>
          <header className="flex flex-col lg:flex-row text-center">
            <img src="/logo.jpeg" alt="" className="h-[90px] hidden lg:flex" />
            <div className="bg-blue-900 w-full flex flex-col items-center justify-center text-white">
              <span className="text-3xl font-black">
                MOLD-TEK PACKAGING LIMITED
              </span>
              <span>
                Registered Office: Plot no.700, Door No. 8-2-293/82/A/700
              </span>
              <span>
                Road No.36 Jubilee Hills, Hyderabad, Telengana - 500 300
              </span>
            </div>
          </header>

          <div className="w-full flex justify-center underline font-semibold text-center bg-gray-300 p-1">
            Proforma Invoice
          </div>

          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border-blue-200 bg-[#964B00] text-white">
              Billing Address
            </div>
            <div className="p-[2px] text-center border border-blue-200 "></div>
            <div className="p-[2px] text-center border border-blue-200">
              KAM
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold"
              contentEditable
            >
              {user?.username}
            </div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              Customer Code:
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold"
              contentEditable
            >
              {billingValue?.value}
            </div>
            <div className="p-[2px] text-center border border-blue-200">
              Date
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold"
              contentEditable
            >
              {CurrentDate}
            </div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              Customer Name:
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold text-blue-500"
              contentEditable
            >
              {billingValue?.["Business Partner (child)"]}
            </div>
            <div className="p-[2px] text-center border border-blue-200">
              PO No.
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold text-blue-500"
              contentEditable
            >
              {masterObj?.po}
            </div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              Full Address:
            </div>
            <ReactSelect
              options={billingAddress}
              className="w-full text-center"
              value={billingValue}
              onChange={(value) => {
                setBillingValue(value);
                console.log(value);
              }}
            />

            <div className="p-[2px] text-center border border-blue-200">
              Invoice name
            </div>
            <div className="p-[2px] text-center border border-blue-200 font-semibold ">
              {invoiceName?.value}
            </div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              City:
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold "
              contentEditable
            >
              {billingValue?.["City"]}
            </div>
            <div className="p-[2px] text-center border border-blue-200 flex items-center justify-center">
              Payment Terms
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold text-blue-500"
              contentEditable
            >
              {masterObj?.["Pay terms_BP"]}
            </div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              Buyer Group:
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold "
              contentEditable
            >
              {masterObj["Buyer Group"]}
            </div>
            <div className="p-[2px] text-center border border-blue-200">
              Delivery Terms
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 text-xs"
              contentEditable
            >
              <ReactSelect
                value={selectedDeliveryTerm}
                placeholder="select terms"
                options={freightOptions}
                className="w-full"
                styles={customStyles}
                onChange={(value) => setDeliveryTerm(value)}
              />
            </div>
          </div>
          <div className="w-full bg-gray-300 p-2" />

          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 bg-[#964B00] text-white">
              Delivery Address
            </div>
            <div className="p-[2px] text-center border border-blue-200 "></div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              Customer Code:
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold"
              contentEditable
            >
              {deliveryValue?.value}
            </div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              Customer Name:
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold text-blue-500"
              contentEditable
            >
              {deliveryValue?.["Business Partner (child)"]}
            </div>
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              Full Address:
            </div>
            <ReactSelect
              value={deliveryValue}
              options={deliveryAddress}
              className="w-full text-center"
              onChange={(value) => setDeliveryValue(value)}
            />
          </div>
          <div className="grid grid-cols-[1fr_2fr_1fr_2fr] bg-[#FDDBC9]">
            <div className="p-[2px] text-center border border-blue-200 ">
              City:
            </div>
            <div
              className="p-[2px] text-center border border-blue-200 font-semibold "
              contentEditable
            >
              {deliveryValue?.["City"]}
            </div>
          </div>
          <div className="w-full flex font-semibold bg-gray-300 p-1">
            Kind Attn.
          </div>
        </div>
        <div className="px-4">
          <DataGrid
            columnHeaderHeight={50}
            rows={data}
            columns={staticColumnsInvoice}
            hideFooter
            editMode="row"
            getRowId={(row) => row.id}
            processRowUpdate={(newRow) => {
              if (isNaN(newRow.qty)) {
                alert("Quantity must be a valid number!");
                return null; // Prevent the update if the value is not a number
              }
              if (isNaN(newRow.rate)) {
                alert("Rate must be a valid number!");
                return null; // Prevent the update if the value is not a number
              }
              // Recalculate total based on qty and rate
              const totalNo = Number(newRow.rate) * Number(newRow.qty);
              const updatedRow = {
                ...newRow,
                total: totalNo.toFixed(2),
              };

              const updatedRows = data.map((row) =>
                row.id === updatedRow.id ? updatedRow : row
              );
              setData(updatedRows);
              return updatedRow;
            }}
            sx={{
              "& .MuiDataGrid-columnSeparator": {
                display: "none", // Remove the column separator lines
              },
              "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
                bgcolor: "#FDDBC9",
                border: "1px solid gray",
                whiteSpace: "normal", // Allows text to wrap
                padding: 0,
                margin: 0,
                paddingInline: 1,
                lineHeight: 1.8,
                // Adjust as needed
              },
              "& .MuiDataGrid-row": {},
              "& .MuiDataGrid-cell": {
                "&.MuiDataGrid-cell--editable:hover": {
                  cursor: "pointer",
                },
                border: "1px solid gray",
                padding: 0,
                alignItems: "center",
                justifyItems: "center",
                textAlign: "center",
                pb: 1,
                overflow: "visible", // Ensure content is visible and does not overflow with ellipsis
                whiteSpace: "normal", // Allow wrapping of text in cells
                textOverflow: "clip", // Remove ellipsis
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold !important",
                overflow: "visible", // Prevents truncation
                textOverflow: "unset", // Removes default ellipsis
                whiteSpace: "normal",
                padding: 0,

                paddingBottom: 1,
              },
              bgcolor: "#FDDBC9",
            }}
          />
        </div>
        <div className="px-4" ref={bottomRef}>
          <div className="flex bg-[#FDDBC9] w-full">
            <div
              className="w-[60%] border border-red-400 p-1"
              contentEditable
            ></div>
            <div className="w-[40%] border border-red-400">
              <div className="grid grid-cols-2">
                <div className="p-[2px] text-center border border-red-400">
                  Freight (Info)
                </div>
                <div className="p-[2px] text-center border border-red-400">
                  <input
                    type="number"
                    className="outline-none border-0 bg-[#FDDBC9] text-center w-full  "
                    value={freight}
                    onChange={handleFreight}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="p-[2px] text-center border border-red-400">
                  GST @18%
                </div>
                <div className="p-[2px] text-center border border-red-400">
                  {sum.gstAmount}
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="p-[2px] text-center border border-red-400">
                  Total
                </div>
                <div className="p-[2px] text-center border border-red-400">
                  {formatCurrency(sum.totalSum)}
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="p-[2px] text-center border border-red-400">
                  Advance
                </div>
                <div className="p-[2px] text-center border border-red-400">
                  <input
                    type="number"
                    className="outline-none border-0 bg-[#FDDBC9] text-center w-full"
                    value={advance}
                    onChange={(e) => setAdvance(e.target.value)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2">
                <div className="p-[2px] text-center border border-red-400">
                  Balance payment
                </div>
                <div className="p-[2px] text-center border border-red-400">
                  {formatCurrency(balanceAmount)}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex bg-gray-300 p-1 text-xs">
            Certified that particulars given above are true and correct and the
            amount indicated represents the price actually charged and there is
            no flow of additional consideration directly or indirectly from the
            buyer
          </div>

          <div className="w-full flex bg-gray-300 text-xs">
            <div className="border border-black flex flex-col flex-1 p-2">
              <span className="font-bold">Payment Details</span>
              <span>Bank Name: ICICI Bank Ltd Hyderabad - 500004</span>
              <span>Branch: Khairatabad</span>
              <span>Account Number : 000851000179</span>
              <span>Account Name : Mold-tek Packaging Limitd</span>
              <span>RTGS/NEFT IFSC Code: ICIC0000008</span>
            </div>

            <div className="border border-black  flex flex-col flex-1 justify-between items-center p-4">
              <span>For: Mold-Tek Packaging Limited</span>

              <span>Authorised Signatory</span>
            </div>
          </div>
        </div>
      </div>
      <ProductSearchModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        products={productArray}
        onSelectProduct={handleSelectProduct}
        onAddNewProduct={handleAddNewProduct}
      />
    </div>
  );
};

export default Invoice;
